import axios from "axios";
import * as eMail from "email-validator";

export default {
  namespaced: true,

  state: {
    startDate: null,
    endDate: null,
    firstname: null,
    lastname: null,
    idNumber: null,
    seats: null,
    email: null,
    phone: null,
    legalEntity: 2,
    offerAccepted: false,
    reservedDates: [],
  },

  mutations: {
    set(state, reservation) {
      state.startDate = reservation.startDate != null ? new Date(reservation.startDate) : null;
      state.endDate = reservation.endDate != null ? new Date(reservation.endDate) : null;
      state.firstname = reservation.firstname;
      state.lastname = reservation.lastname;
      state.idNumber = reservation.idNumber;
      state.seats = reservation.seats;
      state.email = reservation.email;
      state.phone = reservation.phone;
      state.legalEntity = reservation.legalEntity;
      state.offerAccepted = reservation.offerAccepted;
    },

    clear(state) {
      state.startDate = null;
      state.endDate = null;
      state.firstname = null;
      state.lastname = null;
      state.idNumber = null;
      state.seats = null;
      state.email = null;
      state.phone = null;
      state.legalEntity = 2;
      state.offerAccepted = false;
    },

    setReservedDates(state, dates) {
      state.reservedDates = dates
        ? dates
        : null;
    }
  },

  getters: {
    reservationValid: (state) =>
      state.startDate != null && state.startDate <= state.endDate &&
      state.endDate != null && state.endDate >= state.startDate &&
      state.firstname != null && state.firstname.length > 0 &&
      state.lastname != null && state.lastname.length > 0 &&
      state.idNumber != null && state.idNumber.length > 0 &&
      state.seats > 0 && state.seats < 13 &&
      eMail.validate(state.email) &&
      state.phone != null && state.phone.match(/\d/g).length >= 10 &&
      state.legalEntity > 0 && state.legalEntity < 3 &&
      state.offerAccepted,

    startDate: (state) => state.startDate != null && state.startDate <= state.endDate ,

    endDate: (state) =>  state.endDate != null && state.endDate >= state.startDate,

    firstname(state) {
      if (state.firstname != null && state.firstname.length > 0)
        return true;
      return false;
    },

    lastname(state) {
      if (state.lastname != null && state.lastname.length > 0)
        return true;
      return false;
    },

    idNumber(state) {
      if (state.idNumber != null && state.idNumber.length > 0)
        return true;
      return false;
    },

    seats: (state) => state.seats > 0 && state.seats < 13,

    email(state) {
      if (eMail.validate(state.email))
        return true;
      return false;
    },

    phone(state) {
      if (state.phone != null && state.phone.match(/\d/g).length >= 10)
        return true;
      return false;
    },

    legalEntity: (state) => state.legalEntity > 0 && state.legalEntity < 3,

    offerAccepted: (state) => state.offerAccepted,

    postForm: (state) => ({
      startDate: state.startDate.toISOString().substring(0, 10),
      endDate: state.endDate.toISOString().substring(0, 10),
      firstname: state.firstname,
      lastname: state.lastname,
      idNumber: state.idNumber,
      seats: state.seats,
      email: state.email,
      phone: state.phone,
      legalEntity: state.legalEntity,
      offerAccepted: state.offerAccepted
    }),

    ['disabled-dates-ranges']: (state) => {
      return state.reservedDates.map(r => {
          const endDate = new Date(r.endDate);
          endDate.setDate(endDate.getDate() + 2);

        return {
          from: new Date(r.startDate),
          to: endDate
        };
      });
    },

    ['falls-into-reserved']: (state) => (date) => {
      return state.reservedDates.some(r =>
        new Date(date) >= new Date(r.startDate)
        && new Date(date) <= new Date(r.endDate));
    }
  },

  actions: {
    loadReservedDates(store) {
      axios.get("reserved-dates")
        .then(response => {
          store.commit("setReservedDates", response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
}
