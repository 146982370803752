import Vue from 'vue'
import App from './App.vue'

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_ApiUrl;

import store from './store'

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import DatePicker from "vuejs-datepicker";
Vue.component("date-picker", DatePicker);

import VueCustomElement from "vue-custom-element";
Vue.use(VueCustomElement);

App.store = store;
Vue.customElement("booking-widget", App);
