<template>
  <b-card
    class="booking-widget rounded-0"
    border-variant="secondary"
    header-tag="header"
    footer-tag="footer"
    header-bg-variant="info"
    header-text-variant="light"
    header-class="rounded-0"
    footer-bg-variant="light"
  >
    <template #header>
      <h1 class="mb-0">Registration</h1>
    </template>

    <Form :disabled="reservationCreated" />

    <template #footer>
      <b-container>
        <b-row no-gutters>
          <b-col cols=12 sm="10">
            <b-spinner v-if="request" variant="primary"/>

            <b v-for="(item, index) in errors" :key="`errors-${index}`" class="text-danger text-small">
              {{ item }}
            </b>

            <b v-for="(item, index) in results" :key="`results-${index}`" class="text-success">
              {{ item }}
            </b>
          </b-col>

          <b-col cols=12 sm="2" style="text-align: right;">
            <b-button
              size="lg"
              variant="primary"
              :disabled="request || reservationCreated"
              @click="register"
              style="min-width: 120px"
            >
              Register
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-card>
</template>

<style lang="scss">
.booking-widget {
  max-width: 100%;
  margin: auto;
}

.text-small {
  font-size: 0.8rem;
}
</style>

<script>
import axios from "axios";
import { mapState } from "vuex";

import Form from "./views/Form.vue";

export default {
  name: "BookingWidget",
  components: { Form },


  data() {
    return {
      request: false,
      reservationCreated: false,
      errors: [],
      results: []
    };
  },

  created() {
    this.$store.dispatch("reservation/loadReservedDates");
  },

  methods: {
    reservationIntersecting() {
      const start = this.reservation.startDate;
      const end = this.reservation.endDate;

      return this.reservedDates.some(r => start <= new Date(r.endDate) && end >= new Date(r.startDate));
    },

    getFormErrors() {
      const result = [];

      if (!this.$store.getters["reservation/reservationValid"]) {
        result.push("Form data is invalid. Please correct and try again.");
      }

      if (this.reservationIntersecting()) {
        result.push("Selected period is not available.");
      }

      return result;
    },

    register() {
      this.errors = this.getFormErrors();

      if (this.errors.length > 0) {
        return;
      }

      this.request = true;
      const self = this;
      const data = this.$store.getters["reservation/postForm"];

      axios.post("/reservations", data)
        .then((response) => {
          self.results.push(`Reservation #${response.data.id} from ${new Date(response.data.createdUtc).toLocaleString()} is created. Please check your email.`);
          self.reservationCreated = true;
          self.request = false;
        })
        .catch((error) => {
          self.request = false;

          let errors = error.response.data.errors;

          if (errors) {
            for (const error of errors) {
              self.errors.push(error);
            }
          } else if (errors.respoinse.data) {
            self.errors.push(errors.response.data);
          }

          if (error.response?.status === 409) {
            self.errors.push("Registration with the info you've provided was already created, check your email for confirmation letter.");
          }

          if (error.response?.status === 500) {
            self.errors.push("Internal error.");
          }

          console.error(error);
        })

    }
  },

  computed: {
    ...mapState({
      reservedDates: (state) => state.reservation.reservedDates,
      reservation: (state) => ({
        startDate: state.reservation.startDate,
        endDate: state.reservation.endDate,
        firstname: state.reservation.firstname,
        lastname: state.reservation.lastname,
        idNumber: state.reservation.idNumber,
        seats: state.reservation.seats,
        email: state.reservation.email,
        phone: state.reservation.phone,
        legalEntity: state.reservation.legalEntity,
        offerAccepted: state.reservation.offerAccepted,
      }),
    }),
  },
}
</script>
