import Vue from 'vue'
import Vuex from 'vuex'

import reservation from "./modules/reservation.js";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { reservation },
  state: {},
  mutations: {},
  actions: {},
})

//store.dispatch("reservation/loadReservedDates");

export default store;
