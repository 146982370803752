<template>
  <b-form>
    <b-form-row>
      <b-col sm=12 md=6>
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="From"
        >
            <!--
              placeholder="Start date"
              :calendar-button="true"
            -->
          <date-picker
            :disabled="disabled"
            :bootstrap-styling="true"
            :input-class="{ 'is-valid': validation.startDate, 'is-invalid': !validation.endDate }"
            :typeable="true"
            format="yyyy-MM-dd"
            :disabled-dates="disabledStartDates"
            :open-date="openStartDate"
            :highlighted="highlighted"
            v-model="reservation.startDate"
          />
        </b-form-group>
      </b-col>
      <b-col sm=12 md=6>
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="To"
        >
            <!--
              placeholder="End date"
              :calendar-button="true"
            -->
          <date-picker
            :disabled="disabled"
            :bootstrap-styling="true"
            :input-class="{ 'is-valid': validation.endDate, 'is-invalid': !validation.endDate }"
            :typeable="true"
            format="yyyy-MM-dd"
            :disabled-dates="disabledEndDates"
            :open-date="openEndDate"
            :highlighted="highlighted"
            v-model="reservation.endDate"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="First name"
        >
            <!-- placeholder="Bartholomew" -->
          <b-form-input
            :disabled="disabled"
            v-model="reservation.firstname"
            :state="validation.firstname"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="Last name"
        >
            <!-- placeholder="Batista" -->
          <b-form-input
            :disabled="disabled"
            v-model="reservation.lastname"
            :state="validation.lastname"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="ID number"
        >
            <!-- placeholder="1234654321" -->
          <b-form-input
            :disabled="disabled"
            v-model="reservation.idNumber"
            :state="validation.idNumber"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="4"
          label-cols-md="4" content-cols-md="6"
          label-cols-lg="3" content-cols-lg="4"
          label-align="left"
          label-align-sm="right"
          label="Seats"
        >
          <b-form-spinbutton type="number" min="1" max="12"
            :disabled="disabled"
            v-model="reservation.seats"
            :state="validation.seats"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="E-mail"
        >
            <!-- placeholder="triangle@bermuda.do" -->
          <b-form-input
            :disabled="disabled"
            v-model="reservation.email"
            :state="validation.email"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" >
        <b-form-group
          label-cols-sm="3" content-cols-sm="9"
          label-cols-md="4" content-cols-md="8"
          label-cols-lg="3" content-cols-lg="9"
          label-align="left"
          label-align-sm="right"
          label="Phone"
        >
            <!-- placeholder="+7(123)123-45-67" -->
          <b-form-input
            :disabled="disabled"
            v-model="reservation.phone"
            :state="validation.phone"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
        <b-form-group
          label-cols-sm=4 content-cols-sm
          label="Payment as"
        >
          <b-form-radio-group
            :disabled="disabled"
            :options="paymentOptions"
            v-model="reservation.legalEntity"
            :state="validation.legalEntity"
          />
        </b-form-group>
        <b-form-checkbox
          :disabled="disabled"
          :state="validation.offerAccepted"
          v-model="reservation.offerAccepted"
        >
          I accept the <b-link href="https://nirvana.tours/oferta" target="_blank">oferta</b-link>
        </b-form-checkbox>
    </b-form-row>
  </b-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ReservationForm",

  props: {
    disabled: Boolean
  },

  data() {
    return {
      reservation: {
        startDate: this.$store.state.reservation.startDate,
        endDate: this.$store.state.reservation.endDate,
        firstname: this.$store.state.reservation.firstname,
        lastname: this.$store.state.reservation.lastname,
        idNumber: this.$store.state.reservation.idNumber,
        seats: this.$store.state.reservation.seats,
        email: this.$store.state.reservation.email,
        phone: this.$store.state.reservation.phone,
        legalEntity: this.$store.state.reservation.legalEntity,
        offerAccepted: this.$store.state.reservation.offerAccepted,
      },

      paymentOptions: [
        { text: "Person", value: 2},
        { text: "Organization", value: 1}
      ],
    };
  },

  created() {

  },

  computed: {
    ...mapState({
      reservedDates: state => state.reservation.reservedDates,
    }),

    minDate() {
      let date = new Date();
      date.setDate(date.getDate() + 14);
      return date
    },

    validation() {
      return {
        startDate: this.$store.getters["reservation/startDate"],
        endDate: this.$store.getters["reservation/endDate"],
        firstname: this.$store.getters["reservation/firstname"],
        lastname: this.$store.getters["reservation/lastname"],
        idNumber: this.$store.getters["reservation/idNumber"],
        seats: this.$store.getters["reservation/seats"],
        email: this.$store.getters["reservation/email"],
        phone: this.$store.getters["reservation/phone"],
        legalEntity: this.$store.getters["reservation/legalEntity"],
        offerAccepted: this.$store.getters["reservation/offerAccepted"],
      }
    },

    disabledStartDates() {
      const disabledDates = {
        ranges: this.$store.getters["reservation/disabled-dates-ranges"],
        to: new Date()
      };

      disabledDates.to.setDate(disabledDates.to.getDate() + 14);

      return disabledDates;
    },

    openStartDate() {
      const result = new Date();
      result.setDate(result.getDate() + 14);

      for (let i = 0; i < 365; i++) {
        if (this.$store.getters["reservation/falls-into-reserved"](result)) {
          result.setDate(result.getDate() + 1);
        } else {
          break;
        }
      }

      return result;
    },

    disabledEndDates() {
      const disabledDates = {
        ranges: this.$store.getters["reservation/disabled-dates-ranges"],
        to: null
      };

      if (this.reservation.startDate) {
        const to = new Date(this.reservation.startDate);
        to.setDate(to.getDate() + 3);
        disabledDates.to = to;
      } else {
        const to = new Date();
        to.setDate(to.getDate() + 17);
        disabledDates.to = to;
      }

      return disabledDates;
    },

    openEndDate() {
      if (this.reservation.startDate) {
        const date = new Date(this.reservation.startDate);
        date.setDate(date.getDate() + 3);
        return date;
      } else {
        const date = new Date();
        date.setDate(date.getDate() + 17);
        return date;
      }
    },

    highlighted() {
      if (this.reservation.startDate && this.reservation.endDate) {
        return {
          from: this.reservation.startDate,
          to: this.reservation.endDate
        };
      } else {
        return {}
      }
    }
  },

  watch: {
    reservation: {
      deep: true,
      handler(value) {
        this.$store.commit("reservation/set", value);
      }
    }
  }
}
</script>
